/** For now until we remove carbon **/
a {
  color: inherit;
}

#__next,
.chakra-portal [role="alertdialog"], // alert
.chakra-portal [role="dialog"], // modal / drawer
.chakra-portal > [role="region"], // toast
.chakra-portal [role="menu"] { // menu
  zoom: 90%;
}

[role="dialog"][data-size="full"] {
  height: inherit;
  max-width: inherit !important;
  min-height: inherit !important;
  max-height: inherit !important;
}
[role="dialog"][data-height-full="true"] {
  height: inherit;
  min-height: inherit !important;
  max-height: inherit !important;
}

/** Entire app is a flexbox **/
html,
body,
#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'Inter';
  background: #f4f6f8ff;
  overflow: hidden;
}

.chakra-portal {
  font-family: 'Inter';
}

#chakra-toast-manager-bottom-right {
  li {
    margin-right: 48px;
  }
  li:last-child {
    margin-bottom: 48px;
  }
}

#chakra-toast-manager-bottom-left {
  li {
    margin-left: 48px;
  }
  li:last-child {
    margin-bottom: 48px;
  }
}


#chakra-toast-manager-bottom {
  li:last-child {
    margin-bottom: 48px;
  }
}

.ql-editor {
  min-height: 250px;
}

/* for font-family */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font {
  width: 150px !important;
  white-space: nowrap;
}